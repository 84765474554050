import React, { Component } from 'react';
import Checkboxes from './Checkboxes';

export class Step5 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  otherHealth() {
      if(document.getElementById('healthCondition').checked){
        document.getElementById('other-health').style.display = "block"
      } else {document.getElementById('other-health').style.display = "none"}
  }

  render() {
    const { values, handleCheckboxChange } = this.props;
    const checkedArray = Object.entries(values.do_you_have_any_of_these_conditions)
        .filter(([_, value]) => value)
        .map(([key]) => key);

    return (
        <form onSubmit={this.continue}>
            <div className='nhsuk-form-group'>
                <fieldset className="nhsuk-fieldset">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                        <h1 className="nhsuk-fieldset__heading">
                            Do you have any of these health conditions?  
                        </h1>
                    </legend>
                    <span className="nhsuk-hint" id="example-hint">
                        Select all options that are relevant to you.
                    </span>
                    <Checkboxes
                        checkboxes={[
                            { value:'asthma' },
                            { value:'diabetes' },
                            { value:'heart_problem' },
                            { value:'kidney_problem' },
                            { value:'a_weakened_immune_system' },
                            { value:'chronic_lung_condition_besides_asthma' }
                        ]}
                        checked={checkedArray}
                        stateKey='do_you_have_any_of_these_conditions'
                        handleChange={ handleCheckboxChange }
                    />
                </fieldset>
            </div>
            <button className="nhsuk-button">
                Continue
            </button>
        </form>
    );
  }
}

export default Step5;