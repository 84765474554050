import React, { Component } from 'react';
import Checkboxes from './Checkboxes';

export class Step2 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  render() {
    const { values, handleCheckboxChange } = this.props;
    const checkedArray = Object.entries(values.why_are_you_staying_at_home)
        .filter(([_, value]) => value)
        .map(([key]) => key);
        
    return (
        <form onSubmit={this.continue}>
            <div className='nhsuk-form-group'>
                <fieldset className="nhsuk-fieldset">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                        <h1 className="nhsuk-fieldset__heading">
                            Why are you staying at home?
                        </h1>
                    </legend>
                    <span className="nhsuk-hint" id="example-hint">
                        Select all that apply
                    </span>
                    <div className="nhsuk-checkboxes">
                        <Checkboxes
                            checkboxes={[
                                { value:'i_have_coronavirus' },
                                { value:'i_have_been_told_i_have_coronavirus' },
                                { value:'i_have_used_111' },
                                { value:'i_have_symptoms' },
                                { value:'a_family_member_has_coronavirus' },
                                { value:'i_do_not_have_symptoms' }
                            ]}
                            checked={checkedArray}
                            stateKey='why_are_you_staying_at_home'
                            handleChange={ handleCheckboxChange }
                        />
                    </div>
                </fieldset>
            </div>
            <button className="nhsuk-button">
                Continue
            </button>
        </form>
    );
  }
}

export default Step2;
