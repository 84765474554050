import React, { Component } from 'react';
import Start from './Start';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';
import Confirm from './Confirm';
import Success from './Success';

export class UserForm extends Component {
  state = {
    step: 1,
    error: null,
    values: {
      // 1
      are_you_using_the_service: '',
      // 2
      why_are_you_staying_at_home: {
        i_have_coronavirus: false,
        i_have_been_told_i_have_coronavirus: false,
        i_have_used_111: false,
        i_have_symptoms: false,
        a_family_member_has_coronavirus: false,
        i_do_not_have_symptoms: false
      },
      // 3
      do_you_have_any_of_these_symptoms: {
        high_temperature: false,
        sore_throat: false,
        new_cough: false,
        short_breath: false,
        muscle_pain: false,
        loss_of_smell_or_taste: false,
        feeling_tired: false,
        no_symptoms: false,
      },
      // 4
      when_did_your_symptoms_start: {
        'today': false,
        '1_day_ago': false,
        '2_days_ago': false,
        '3_days_ago': false,
        '4_days_ago': false,
        '5_days_ago': false,
        '6_days_ago': false,
        'other': false
      },
      when_did_your_symptoms_start_other: '',
      // 5
      do_you_have_any_of_these_conditions: {
        asthma: false,
        diabetes: false,
        heart_problem: false,
        kidney_problem: false,
        a_weakened_immune_system: false,
        chronic_lung_condition_besides_asthma: false,
        no_other_health_conditions: false
      },
      // 6
      date_of_birth: {
        day: '',
        month: '',
        year: ''
      },
      // 7
      post_code: '',
      // 8
      how_many_people_do_you_live_with: '',
      // 9
      text_message_updates: '',
      mobile_number: null
    }
  }

  // Proceed to next step
  nextStep = () => {
    this.setState(({step}) => ({ step: step + 1}));
  };

  // Set value
  setValue = (key, val) => {
    this.setState((state) => ({ 
      values: {
        ...state.values,
        [key]: val
      }
    }));
  };

  // Handle input change
  handleChange = key => e => {
    this.setValue(key, e.target.value)
  };

  handleCheckboxChange = key => e => {
    const value = e.target.value;
    const checked = e.target.checked;
    this.setState((state) => ({ 
      values: {
        ...state.values,
        [key]: {
          ...state.values[key],
          [value]: checked
        }
      }
    }));
  };

  handleDateChange = (key, field) => e => {
    const value = e.target.value;
    this.setState((state) => ({ 
      values: {
        ...state.values,
        [key]: {
          ...state.values[key],
          [field]: value
        }
      }
    }));
  };

  setError = error => {
    this.setState({ error });
  };

  goToStep = (step, scrollTop = true) => {
    this.setState({ step });
    if (scrollTop) window.scrollTo(0, 0);
  };

    //Go to conf - No mobile
    nextNoMobileConf = () => {
      const { step } = this.state;
      this.setState({
        step: step + 2
      });
      window.scrollTo(0, 0);
    };

  render() {
    const { step } = this.state;
    const stepProps = {
      nextStep: this.nextStep,
      handleChange: this.handleChange,
      setValue: this.setValue,
      setError: this.setError,
      values: this.state.values,
      error: this.state.error,
      step: this.state.step
    }

    switch (step) {
      default:
        return <Start { ...stepProps }/>
      case 2:
        return <Step1 { ...stepProps } />
      case 3:
        return <Step2 { ...stepProps } handleCheckboxChange={this.handleCheckboxChange} />
      case 4:
        return <Step3 { ...stepProps } handleCheckboxChange={this.handleCheckboxChange} />
      case 5:
        return <Step4 { ...stepProps } />
      case 6:
        return <Step5 { ...stepProps } handleCheckboxChange={this.handleCheckboxChange} />
      case 7:
        return <Step6 { ...stepProps } handleDateChange={this.handleDateChange} />
      case 8:
        return <Step7 { ...stepProps } />
      case 9:
        return <Step8 { ...stepProps } />
      case 10:
        return <Step9 { ...stepProps } goToStep={this.goToStep} />    
      case 11:
        return <Step10 { ...stepProps } />             
      case 12:
        return <Confirm
            nextStep={this.nextStep}
            goToStep={this.goToStep}
            values={this.state.values}
          />
      case 13:
        return <Success values={this.state.values} />;
    }
  }
}

export default UserForm;