import React, { Component } from 'react';

export class Start extends Component {
  
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    return (
      <React.Fragment>
        <h1>Tell us if you’re staying at home because of coronavirus</h1>
        <p className='nhsuk-lede-text'>This is a research tool to help us record how many people are staying at home due to coronavirus (COVID-19).</p>
        <p>By answering some quick questions you can help the NHS to understand:</p>

        <ul>
          <li>how people are being affected by coronavirus</li>
          <li>the number of people recovering from coronavirus</li>
          <li>which areas of the country need resources most</li>
        </ul>

        <div className='nhsuk-inset-text'>
          <span className='nhsuk-u-visually-hidden'>Information: </span>
          <p>We will not share the answers you give us beyond the Department of Health and Social Care.</p>
        </div>

        <p>If you tell us your mobile number we'll send you daily advice about how to look after the mental and physical health of yourself and others while you're at home.</p>
        <p>If you complete this survey for someone else, you’ll need to tell us their details.</p>

        <button
          className='nhsuk-button'
          draggable='false'
          onClick={this.continue}
        >
          Start Now
        </button>
      </React.Fragment>
    );
  }
}

export default Start;
