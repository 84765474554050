import React, { Component } from 'react';
import Error from './Error';
import Radios from './Radios';

export class Step1 extends Component {
  continue = e => {
    e.preventDefault();
    if (this.validate()) {
        this.props.setError(null)
        this.props.nextStep();
    } else {
        this.props.setError('This field is required')
    }
  }

  validate = () => {
    return Boolean(this.props.values.are_you_using_the_service);
  }

  render() {
    const { error, values, handleChange } = this.props;
    return (
        <form onSubmit={this.continue}>
            <div className={ error ? 'nhsuk-form-group nhsuk-form-group--error' : 'nhsuk-form-group'}>
                <fieldset className="nhsuk-fieldset">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                        <h1 className="nhsuk-fieldset__heading">
                            Are you completing this survey for yourself or someone else?
                        </h1>
                    </legend>
                    <span className="nhsuk-hint" id="example-hint">
                        You can complete this survey for someone else if you have their permission. 
                    </span>
                    <Error error={error} />
                    <Radios
                        radios={[
                            { value:'for_myself' },
                            { value:'for_someone_else' }
                        ]}
                        checked={ values.are_you_using_the_service }
                        stateKey='are_you_using_the_service'
                        handleChange={ handleChange }
                    />
                </fieldset>
            </div>
            <button className="nhsuk-button">
                Continue
            </button>
        </form>
    );
  }
}

export default Step1;
