export const checkboxDisplayMappings = {
	'i_have_coronavirus': 'I have been tested positive for coronavirus',
	'i_have_been_told_i_have_coronavirus': 'My GP or another healthcare professional has told me I have coronavirus based on my symptoms',
	'i_have_used_111': 'I’ve used the 111 coronavirus service (either online or by calling 111)',
	'i_have_symptoms': 'I have coronavirus symptoms',
	'a_family_member_has_coronavirus': 'A family member or person I live with has coronavirus',
	'i_do_not_have_symptoms': 'I do not have any coronavirus symptoms myself but I am staying at home',

	'high_temperature': 'High temperature or fever (you feel hot to touch on your chest or back)',
	'sore_throat': 'Sore throat',
	'new_cough': 'New continuous cough',
	'short_breath': 'Shortness of breath',
	'muscle_pain': 'Muscle or joint pain',
	'loss_of_smell_or_taste': 'Loss of smell or taste',
	'feeling_tired': 'Feeling tired or exhausted',

	'asthma': 'Asthma',
	'diabetes': 'Diabetes',
	'heart_problem': 'Heart problem',
	'kidney_problem': 'Kidney problem',
	'a_weakened_immune_system': 'A weakened immune system',
	'chronic_lung_condition_besides_asthma': 'Chronic lung condition besides asthma e.g. COPD, emphysema, or other conditions that affect your breathing'
};

export const radioDisplayMappings = {
	'for_myself': 'For myself',
	'for_someone_else': 'For someone else',

	'yes': 'Yes',
	'no': 'No',
	'no_mobile_phone': 'I don\'t have a mobile phone',

	'today': 'Today',
	'1_day_ago': '1 day ago',
	'2_days_ago': '2 days ago',
    '3_days_ago': '3 days ago',
    '4_days_ago': '4 days ago',
	'5_days_ago': '5 days ago',
    '6_days_ago': '6 days ago',
    'other': 'Other'
};
