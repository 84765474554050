import React, { Component } from 'react';
import axios from 'axios';

import { checkboxDisplayMappings, radioDisplayMappings } from '../constants';

export class Confirm extends Component {
    continue = e => {
        e.preventDefault();
        this.sendData();
    };

    formatDate = ({ day, month, year }) => {
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
    }

    sendData = async () => {
        const { values } = this.props;
        const date_of_birth = this.formatDate(values.date_of_birth);
        const how_many_people_do_you_live_with = values.how_many_people_do_you_live_with*1;
        const when_did_your_symptoms_start_other = values.when_did_your_symptoms_start_other*1;
        // const apiUrl = 'https://api.tom.dev.covid-19.forms.digital.nhs.uk/submission';
        const apiUrl = window.location.href.replace('https://', 'https://api.') + 'submission';
        
        const transformedState = {
            answers: {
                ...values,
                date_of_birth,
                how_many_people_do_you_live_with,
                when_did_your_symptoms_start_other
            }
        }

        try {
            await axios.post(apiUrl, transformedState);
            this.props.nextStep();
        }
        catch (error) {
            console.log(error);
            // Still go to confirmation no matter what for now
            this.props.nextStep();
        }
    }

    goToStep = (step) => e => {
        e.preventDefault();
        this.props.goToStep(step);
    };

    render() {
        const { values } = this.props;
        const dob = values.date_of_birth;
        return (
            <React.Fragment>
                <h1>Check your answers</h1>
                <h2>Coronavirus symptoms</h2>
                <dl className="nhsuk-summary-list">
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Staying at home due to coronavirus advice
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            { radioDisplayMappings[values.are_you_using_the_service] }
                        </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(2)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Reason for staying at home
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            <ul className='nhsuk-list'>
                                { Object.entries(values.why_are_you_staying_at_home)
                                    .filter(([_, value]) => value)
                                    .map(([key]) => (<li key={key}>{checkboxDisplayMappings[key]}</li>)) }
                            </ul>
                       </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(3)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Current symptoms
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            <ul className='nhsuk-list'>
                                { Object.entries(values.do_you_have_any_of_these_symptoms)
                                    .filter(([_, value]) => value)
                                    .map(([key]) => (<li key={key}>{checkboxDisplayMappings[key]}</li>)) }
                            </ul>
                       </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(4)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            When did your symptoms start
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            { values.when_did_your_symptoms_start === 'other'
                                ? values.when_did_your_symptoms_start_other
                                : radioDisplayMappings[values.when_did_your_symptoms_start] }
                        </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(5)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Do you have any of these conditions
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            <ul className='nhsuk-list'>
                                { Object.entries(values.do_you_have_any_of_these_conditions)
                                    .filter(([_, value]) => value)
                                    .map(([key]) => (<li key={key}>{checkboxDisplayMappings[key]}</li>)) }
                            </ul>
                        </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(6)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                </dl>
                <h2 className="nhsuk-heading-m">Personal details</h2>
                <dl className="nhsuk-summary-list">
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Date of birth
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            { `${dob.day}/${dob.month}/${dob.year}` }
                        </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(7)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Postcode
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            { values.post_code }
                        </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(8)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Number of other people living in your household
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            { values.how_many_people_do_you_live_with }
                        </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(9)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                            Subscribe to text updates
                        </dt>
                        <dd className="nhsuk-summary-list__value">
                            { radioDisplayMappings[values.text_message_updates] }
                        </dd>
                        <dd className="nhsuk-summary-list__actions">
                            <a className="nhsuk-link" href="/" onClick={this.goToStep(10)}>
                                Change<span className="nhsuk-visually-hidden"></span>
                            </a>
                        </dd>
                    </div>
                    { values.mobile_number &&
                        <div className="nhsuk-summary-list__row">
                            <dt className="nhsuk-summary-list__key">
                                Mobile telephone number
                            </dt>
                            <dd className="nhsuk-summary-list__value">
                                { values.mobile_number }
                            </dd>
                            <dd className="nhsuk-summary-list__actions">
                                <a className="nhsuk-link" href="/" onClick={this.goToStep(11)}>
                                    Change<span className="nhsuk-visually-hidden"></span>
                                </a>
                            </dd>
                        </div>
                    }
                </dl>
                <button className="nhsuk-button" onClick={this.continue}>
                    Accept and send
                </button>
            </React.Fragment>
        );
    }
}

export default Confirm;
