import React, { Component } from 'react';
import Error from './Error';

export class Step10 extends Component {
    continue = e => {
        e.preventDefault();
        if (this.validate()) {
            this.props.setError(null)
            this.props.nextStep();
        } else {
            this.props.setError('This field is required')
        }
    }

    validate = () => {
        return Boolean(this.props.values.mobile_number);
    }

  render() {
    const { error, values, handleChange } = this.props;
    return (
        <form onSubmit={this.continue}>
            <div className={ error ? 'nhsuk-form-group nhsuk-form-group--error' : 'nhsuk-form-group'}>
                <h1 className="nhsuk-label-wrapper">
                    <label className="nhsuk-label nhsuk-label--l" htmlFor="number">
                        What is your mobile telephone number?
                    </label>
                </h1>
                <Error error={error} />
                <input className="nhsuk-input nhsuk-input--width-10" id="number" value={ values.mobile_number } onChange={handleChange('mobile_number')} type="number" />
            </div>        
            <details className="nhsuk-details">
                <summary className="nhsuk-details__summary" role="button" tabIndex="0">
                    <span className="nhsuk-details__summary-text">
                    I'm using this service on behalf of someone else
                    </span>
                </summary>
                <div className="nhsuk-details__text">
                    <p>If you’re using this service for someone else who does not have a mobile phone, you can give your own number and we can send you updates for them.</p>
                </div>
            </details>
            <button className="nhsuk-button">
                Continue
            </button>
        </form>
    );
  }
}

export default Step10;