import React, { Component } from 'react';
import Error from './Error';

export class Step6 extends Component {
    continue = e => {
        e.preventDefault();
        if (this.validate()) {
            this.props.setError(null)
            this.props.nextStep();
        } else {
            this.props.setError('Please enter a valid date')
        }
    }

    validate = () => {
        const { day, month, year } = this.props.values.date_of_birth;
        const parsedDate = Date.parse(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
        return (Boolean(parsedDate));
      }

    render() {
        const { error, values, handleDateChange } = this.props;
        return (
            <form onSubmit={this.continue}>
                <div className={error ? 'nhsuk-form-group nhsuk-form-group--error' : 'nhsuk-form-group'}>
                    <fieldset className="nhsuk-fieldset" aria-describedby="hint" role="group">
                        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                            <h1 className="nhsuk-fieldset__heading">
                                What is your date of birth?
                            </h1>
                        </legend>
                        <span className="nhsuk-hint" id="hint">
                            For example, 15 3 1984
                        </span>
                        <Error error={error} />
                        <div className="nhsuk-date-input" id="example">
                            <div className="nhsuk-date-input__item">
                                <div className="nhsuk-form-group">
                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="day">
                                        Day
                                     </label>
                                    <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                                        id="day"
                                        pattern="[0-9]*"
                                        onChange={handleDateChange('date_of_birth', 'day')}
                                        value={values.date_of_birth.day}
                                    />
                                </div>
                            </div>
                            <div className="nhsuk-date-input__item">
                                <div className="nhsuk-form-group">
                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="month">
                                        Month
                                    </label>
                                    <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                                        id="month"
                                        pattern="[0-9]*"
                                        onChange={handleDateChange('date_of_birth', 'month')}
                                        value={values.date_of_birth.month}
                                    />
                                </div>
                            </div>
                            <div className="nhsuk-date-input__item">
                                <div className="nhsuk-form-group">
                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="year">
                                        Year
                                </label>
                                    <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
                                        id="year"
                                        pattern="[0-9]*"
                                        onChange={handleDateChange('date_of_birth', 'year')}
                                        value={values.date_of_birth.year}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <details className="nhsuk-details">
                    <summary className="nhsuk-details__summary" role="button" tabIndex="0">
                        <span className="nhsuk-details__summary-text">
                            Why we are asking this question
                    </span>
                    </summary>
                    <div className="nhsuk-details__text">
                        <p>We will not use this information to identify you.</p>
                        <p>We need this information to help us understand how coronavirus is affecting different age groups.</p>
                    </div>
                </details>
                <button className="nhsuk-button">
                    Continue
            </button>
            </form>
        );
    }
}

export default Step6;