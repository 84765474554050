import React from 'react';

import { radioDisplayMappings } from '../constants';

export default ({radios, handleChange, stateKey, checked}) => (
    <div className="nhsuk-form-group">
        <div className="nhsuk-radios">
            { radios.map(({value}, index) => (
                 <div className="nhsuk-radios__item" key={'radio' + index}>
                    <input className="nhsuk-radios__input"
                        onChange={handleChange(stateKey)}
                        id={'radio' + index}
                        name="radio"
                        type="radio"
                        checked={ checked === value }
                        value={value}
                    />
                    <label className="nhsuk-label nhsuk-radios__label" htmlFor={'radio' + index}>
                        { radioDisplayMappings[value] }
                    </label>
                </div>
            ))}
        </div>
    </div>
);
