import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import UserForm from "./components/UserForm";

export default () => (
  <React.Fragment>
    <Header />
      <div className="nhsuk-width-container ">
        <div className="nhsuk-main-wrapper " id="maincontent" role="main"> 
          <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
              <UserForm />
            </div>
          </div>
        </div>
      </div>
    <Footer />
  </React.Fragment>
);
