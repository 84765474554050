import React, { Component } from 'react';
import Error from './Error';
import Radios from './Radios';

export class Step4 extends Component {

  continue = e => {
    e.preventDefault();
      var radios = document.querySelectorAll('input[type=radio]');
      var formValid = false;
      var i = 0;
      while (!formValid && i < radios.length) {
          if (radios[i].checked) {formValid = true;
            this.props.setError(null)
              this.props.nextStep();}
          i++;        
      }
      if (!formValid) this.props.setError('This field is required');
      return formValid;
  }
  
  render() {
    const { error, values, handleChange } = this.props;
    return (
      <form onSubmit={this.continue}>
        <div className={error ? 'nhsuk-form-group nhsuk-form-group--error' : 'nhsuk-form-group'}>
          <fieldset className="nhsuk-fieldset" aria-describedby="hint" role="group">
            <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
              <h1 className="nhsuk-fieldset__heading">
                When did your symptoms start?
              </h1>
            </legend>
            <span className="nhsuk-hint" id="hint">
              If you are not sure which day your symptoms first started, select an approximate date.
            </span>
            <Error error={error} />
            <Radios
                radios={[
                    { value:'today' },
                    { value:'1_day_ago' },
                    { value:'2_days_ago' },
                    { value:'3_days_ago' },
                    { value:'4_days_ago' },
                    { value:'5_days_ago' },
                    { value:'6_days_ago' },
                    { value:'other' }
                ]}
                checked={ values.when_did_your_symptoms_start }
                stateKey='when_did_your_symptoms_start'
                handleChange={ handleChange }
                name={'yesno'}
            />
            { values.when_did_your_symptoms_start === 'other' &&
              <div className="nhsuk-form-group">
                <label className="nhsuk-label" htmlFor="when_did_your_symptoms_start_other">
                    Number of days since your symptoms started
                </label>
                <input className="nhsuk-input nhsuk-input--width-3"
                  id="when_did_your_symptoms_start_other"
                  pattern="[0-9]*"
                  value={ values.when_did_your_symptoms_start_other }
                  onChange={handleChange('when_did_your_symptoms_start_other')}
                />
              </div>
            }
          </fieldset>
        </div>
        <button className="nhsuk-button">
          Continue
        </button>
      </form>
    );
  }
}

export default Step4;
