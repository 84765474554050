import React, { Component } from 'react';
import Error from './Error';

export class Step8 extends Component {
    continue = e => {
        e.preventDefault();
        if (this.validate()) {
            this.props.setError(null)
            this.props.nextStep();
        } else {
            this.props.setError('This field is required')
        }
    }

    validate = () => {
        return Boolean(this.props.values.how_many_people_do_you_live_with);
    }

    render() {
        const { error, values, handleChange } = this.props;
        return (
            <form onSubmit={this.continue}>
                <div className={error ? 'nhsuk-form-group nhsuk-form-group--error' : 'nhsuk-form-group'}>
                    <h1 className="nhsuk-label-wrapper">
                        <label className="nhsuk-label nhsuk-label--l" htmlFor="live">
                            How many people do you live with?
                    </label>
                    </h1>
                    <span className="nhsuk-hint" id="example-hint">
                        Tell us the number of other people you live with. If you live alone, enter 0.
                    </span>
                    <Error error={error} />
                    <input className="nhsuk-input nhsuk-input--width-5" 
                        id="live" 
                        pattern="[0-9]*"
                        value={ values.how_many_people_do_you_live_with}
                        onChange={handleChange('how_many_people_do_you_live_with')} type="text"
                    />
                </div>
                <button className="nhsuk-button">
                        Continue
                </button>
            </form>
        );
    }
}

export default Step8;