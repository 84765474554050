import React from 'react';

import { checkboxDisplayMappings } from '../constants';

export default ({ checkboxes, handleChange, stateKey, checked }) => (
    <div className="nhsuk-form-group">
        <div className="nhsuk-checkboxes">
            { checkboxes.map(({ value }, index) => (
                 <div className="nhsuk-checkboxes__item" key={'checkbox' + index}>
                    <input className="nhsuk-checkboxes__input"
                        onChange={handleChange(stateKey)}
                        id={'checkbox' + index}
                        type="checkbox"
                        value={value}
                        checked={ checked.includes(value) }
                    />
                    <label className="nhsuk-label nhsuk-checkboxes__label" htmlFor={'checkbox' + index}>
                        { checkboxDisplayMappings[value] }
                    </label>
                </div>
            ))}
        </div>
    </div>
);
