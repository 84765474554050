import React, { Component } from 'react';

export class Success extends Component {
  render() {
    const { values } = this.props;
    const divStyle = {
      background: "#005EB8",
      textAlign: "center",
      color: "white",
      padding: "20px",
      margin: "30px 0"
    };
    return (
        <React.Fragment>
          {/* */}
            <div className="done-panel" style={divStyle}>
                <h1 className="heading-large"><span id="done-tick"> </span>Thank you for sharing this information.</h1>
                { values.mobile_number &&
                  <div id="oneoff_done">
                        <h2 className="heading-medium">You'll get your first text update from us soon.</h2>
                    </div>
                }
                
            </div>
            <h3 className="heading-medium">What you need to do next</h3>
            <p>Stay up-to-date with the latest <a href="https://www.gov.uk/government/publications/covid-19-guidance-on-social-distancing-and-for-vulnerable-people/guidance-on-social-distancing-for-everyone-in-the-uk-and-protecting-older-people-and-vulnerable-adults">Government advice</a>.</p>
            <p>Get the latest <a href="https://www.nhs.uk/conditions/coronavirus-covid-19/" target="_blank" rel="noopener noreferrer">NHS information on coronavirus</a>, including advice on staying at home.</p>

            <div className="nhsuk-inset-text">
                <span className="nhsuk-u-visually-hidden">Information: </span>
                <h3>If you get symptoms of coronavirus, or if your symptoms get worse, get advice from:</h3>
                <ul>
                    <li>England:<a href="https://111.nhs.uk/covid-19" target="_blank" rel="noopener noreferrer">NHS 111 online coronavirus service</a></li>
                    <li>Scotland:<a href="https://www.nhsinform.scot/illnesses-and-conditions/infections-and-poisoning/coronavirus-covid-19" target="_blank" rel="noopener noreferrer">NHS inform</a></li>
                    <li>Wales: <a href="https://www.nhsdirect.wales.nhs.uk/" target="_blank" rel="noopener noreferrer">NHS Direct Wales</a></li>
                    <li>Northern Ireland: Call 111</li>
                </ul>
            </div>
        </React.Fragment>

    );
  }
}

export default Success;
