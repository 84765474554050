import React from "react";

export default () => (
    <footer role="contentinfo">
        <div className="nhsuk-footer" id="nhsuk-footer">
            <div className="nhsuk-width-container">
                <h2 className="nhsuk-u-visually-hidden">Support links</h2>
                <p className="nhsuk-u-secondary-text-color nhsuk-u-font-size-16">This website only stores the cookies that are needed to make it work. Read more about how <a href="/docs/cookies" className="nhsuk-footer__list-item-link">we use cookies.</a></p>
                <ul className="nhsuk-footer__list">
                    <li className="nhsuk-footer__list-item"><a className="nhsuk-footer__list-item-link" href="about/">About</a></li>
                </ul>
                <p className="nhsuk-footer__copyright">&copy; Crown copyright</p>
            </div>
        </div>
    </footer>
);
