import React, { Component } from 'react';
import Error from './Error';
import Radios from './Radios';

export class Step9 extends Component {
    continue = e => {
        e.preventDefault();
        if (this.validate()) {
            this.props.setError(null);
            if (this.props.values.text_message_updates === 'yes') {
                this.props.nextStep();
            } else {
                this.props.setValue('mobile_number', null)
                this.props.goToStep(12);
            }
        } else {
            this.props.setError('This field is required')
        }
    }

    validate = () => {
        return Boolean(this.props.values.text_message_updates);
    }

  render() {
    const { error, values, handleChange } = this.props;
    return (
        <form onSubmit={this.continue}>
            <div className={ error ? 'nhsuk-form-group nhsuk-form-group--error' : 'nhsuk-form-group'}>
                <fieldset className="nhsuk-fieldset">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                        <h1 className="nhsuk-fieldset__heading">
                            Would you like to get updates by text?
                        </h1>
                    </legend>
                    <p>We’ll send you tips and reminders while you’re staying at home about:</p>
                    <ul>
                        <li>how to protect yourself and other people</li>
                        <li>what to do if you live with other people who are also staying at home</li>
                        <li>how to look after your mental health and wellbeing</li>
                    </ul>
                    
                    <span className="nhsuk-hint" id="hint">
                        Select one option.
                    </span>
                    <Error error={error} />
                    <Radios
                        radios={[
                            { value:'yes' },
                            { value:'no' },
                            { value:'no_mobile_phone' }
                        ]}
                        checked={ values.text_message_updates }
                        stateKey='text_message_updates'
                        handleChange={ handleChange }
                    />
                    <br/>
                    <details className="nhsuk-details">
                        <summary className="nhsuk-details__summary" role="button">
                            <span className="nhsuk-details__summary-text">
                                How we'll use your mobile number
                            </span>
                        </summary>
                        <div className="nhsuk-details__text">
                            <p>We will not use this information to identify you.</p>
                            <p>We will only use your mobile number to text you updates.</p> 
                            <p>You can opt out at any time.</p>
                        </div>
                    </details>
                </fieldset>
            </div>
            <button className="nhsuk-button">
                Continue
            </button>
        </form>
    );
  }
}

export default Step9;